import React from "react";
import {
  StyledTwoColumnLayout,
  StyledTwoColumnLayoutFirst,
  StyledTwoColumnLayoutSecond,
} from "./style";
import loadable from "@loadable/component";

const Component = loadable(props => import(`../${props.componentName}/${props.componentName}`),{
  cacheKey: props => props.componentName,
});
const createComponent = ({ component, props }, index) => {
  return (
    <Component
      key={index}
      componentName={component}
      {...props}
    />
  );
};

export default ({
  breakpoint = "tablet",
  firstColumnWidthPercentage = 50,
  reverseAfterBreakpoint = false,
  firstColumnComponents = [],
  secondColumnComponents = [],
  isFirstFull = false,
  isSecondFull = false,
  firstAlignSelf = "auto",
  secondAlignSelf = "auto",
  header = false,
}) => {
  return (
    <StyledTwoColumnLayout breakpoint={breakpoint} header={header} reverseAfterBreakpoint={reverseAfterBreakpoint}>
      <StyledTwoColumnLayoutFirst breakpoint={breakpoint} widthPercentage={firstColumnWidthPercentage} isFull={isFirstFull} alignSelf={firstAlignSelf}>
        {firstColumnComponents.map(createComponent)}
      </StyledTwoColumnLayoutFirst>
      <StyledTwoColumnLayoutSecond breakpoint={breakpoint} widthPercentage={100-firstColumnWidthPercentage} isFull={isSecondFull} alignSelf={secondAlignSelf}>
        {secondColumnComponents.map(createComponent)}
      </StyledTwoColumnLayoutSecond>
    </StyledTwoColumnLayout>
  );
};
